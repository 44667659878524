<template>
	<div id="why-us" class="page-section py-5">
		<v-layout wrap justify-center class="text-center">
			<v-flex xs12 sm12 md12 class="text-h4 font-weight-bold lg-header-link-1--text">{{
				getSite.processHeader
			}}</v-flex>
			<v-flex xs12 sm12 md5 class="text-subtitle-1 mt-5">
				{{ getSite.processDescription }}
			</v-flex>
			<template v-for="(whyUs, index) in whyUsContents">
				<v-flex xs12 sm12 md12 :key="`whyUs-${index}`">
					<v-flex xs12 sm12 md12 lg12 class="why-use-row my-8 d-flex justify-center">
						<v-flex
							xs10
							sm6
							md6
							lg4
							class="d-flex text-left fill-height align-center"
							:class="{ 'flex-row-reverse text-right': index % 2 === 0 }"
						>
							<v-flex xs3 sm4 md3 lg2 :class="{ 'd-flex justify-end': index % 2 === 0 }">
								<v-img
									contain
									max-width="76"
									max-height="70"
									width="76"
									height="70"
									:src="whyUs.icon"
									:class="whyUseImgMargin(index)"
								></v-img>
							</v-flex>
							<v-flex xs9 sm8 md9 lg10 class="px-1">
								<div
									:class="$vuetify.breakpoint.mdAndDown ? 'text-subtitle-1' : 'text-h5'"
									class="lg-header-link-1--text font-weight-bold"
								>
									{{ whyUs.title }}
								</div>
								<div :class="{ spacing: $vuetify.breakpoint.mdAndDown }">{{ whyUs.desc }}</div>
							</v-flex>
						</v-flex>
					</v-flex>
				</v-flex>
			</template>
		</v-layout>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'WhyUse',
	data() {
		return {};
	},
	computed: {
		...mapGetters(['getSite', 'darkTemplate']),
		whyUsContents() {
			return this.getSite?.businessStep;
		},
	},
	methods: {
		whyUseImgMargin(index) {
			let direction = '';

			direction = index % 2 === 0 ? 'ml' : 'mr';

			return this.$vuetify.breakpoint.mdAndDown ? `${direction}-5` : `${direction}-10`;
		},
	},
};
</script>

<style lang="scss" scoped>
#why-us {
	background-color: #f1f1f1 !important;
}
</style>
