<template>
	<div>
		<SmsApprove />
		<v-card class="wizard-card car-wizard-info" elevation="0">
			<v-card-text class="rounded pa-10">
				<v-row dense>
					<v-col cols="12" :sm="smSize" class="px-7">
						<v-subheader>Cep Telefonu *</v-subheader>
						<v-text-field-simplemask
							v-model="$v.contact.phoneNumber.$model"
							v-bind:label="'Cep telefonunuzu girin'"
							v-bind:properties="{
								inputmode: 'numeric',
								prefix: '+90',
								solo: true,
								dense: true,
								flat: true,
								class: 'rounded-lg',
								errorMessages: phoneNumberErrors(),
								hideDetails: 'auto',
							}"
							v-bind:options="{
								inputMask: '(###)-###-##-##',
								outputMask: '##########',
								empty: null,
								applyAfter: false,
								alphanumeric: true,
								lowerCase: false,
							}"
						/>
					</v-col>
					<v-col cols="12" :sm="smSize" class="px-7">
						<v-subheader>E-Mail Adresi</v-subheader>
						<v-text-field
							inputmode="email"
							label="E-Mail Adresinizi girin"
							solo
							hide-details="auto"
							flat
							dense
							class="rounded-lg"
							v-model="$v.contact.email.$model"
							:error-messages="emailErrors()"
						></v-text-field>
					</v-col>
					<v-col cols="12" :sm="smSize" class="px-7">
						<v-subheader>İsim - Soyisim *</v-subheader>
						<v-text-field
							label="İsim ve soyisim girin"
							solo
							hide-details="auto"
							flat
							dense
							class="rounded-lg"
							v-model="$v.contact.name.$model"
							:error-messages="nameErrors()"
						></v-text-field>
					</v-col>
					<v-col cols="12" :sm="smSize" class="px-7">
						<v-subheader>Şehir <template v-if="getSite.isCityMandatory">*</template></v-subheader>
						<v-select
							item-text="citName"
							item-value="citId"
							label="Şehrinizi seçin"
							solo
							hide-details="auto"
							flat
							dense
							class="rounded-lg"
							v-model="$v.contact.cityId.$model"
							:error-messages="cityErrors()"
							:items="getCities"
						></v-select>
					</v-col>
					<v-col
						cols="12"
						:sm="smSize"
						class="px-7"
						v-if="getSite.isLocation && getSite.scheduleType === locationScheduleType.CONTACTPAGE"
					>
						<v-subheader>Lokasyon <template>*</template></v-subheader>
						<v-select
							item-text="name"
							item-value="id"
							label="Lokasyon seçin"
							solo
							hide-details="auto"
							flat
							dense
							class="rounded-lg"
							v-model="$v.contact.locationId.$model"
							:error-messages="locationErrors()"
							:items="getLocationList"
						></v-select>
					</v-col>
					<v-col md="12" class="px-7">
						<v-checkbox
							color="template-color darken-2"
							:dark="darkTemplate"
							dense
							hide-details="auto"
							:error-messages="kvkkApprovedErrors()"
							v-model="$v.contact.kvkkApproved.$model"
						>
							<template v-slot:label>
								<div class="caption">
									<span
										class="caption font-weight-bold"
										:class="darkTemplate ? 'template-text--text' : ' template-color--text'"
									>
										{{ getSite.companyName }}
										<a
											:class="darkTemplate ? 'template-text--text' : ' template-color--text'"
											:href="getSite.endUserContractFile"
											target="_blank"
											@click.stop
											>KVKK Aydınlatma Metnini</a
										>
										okuyup anladığımı, verilerimin işlenmesi, aktarılması ve kullanılmasına bu çerçevede açıkça rıza
										gösterdiğimi beyan ve kabul ederim.
									</span>
								</div>
							</template>
						</v-checkbox>
					</v-col>
					<v-col md="12" class="px-7">
						<v-checkbox
							color="template-color darken-2"
							:dark="darkTemplate"
							dense
							hide-details="auto"
							v-model="contact.contactApproved"
						>
							<template v-slot:label>
								<div class="caption">
									<span
										class="caption font-weight-bold"
										:class="darkTemplate ? 'template-text--text' : ' template-color--text'"
									>
										{{ getSite.companyName }}
										ürün/hizmet teklifleri için iletişim kurulmasını kabul ediyorum.
									</span>
								</div>
							</template>
						</v-checkbox>
					</v-col>
					<v-col md="12" class="d-flex flex-column align-center justify-center pt-5">
						<vue-recaptcha
							ref="recaptcha"
							@verify="onVerifyCaptcha"
							@expired="onExpiredCaptcha"
							:sitekey="captchaSiteKey"
							:loadRecaptchaScript="true"
						>
						</vue-recaptcha>
						<div class="error--text" v-if="$v.captchaValid.$error">Lütfen robot olmadığınızı doğrulayın!</div>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions class="d-flex justify-center py-5">
				<v-btn
					shaped
					outlined
					color="template-color"
					class="px-5 rounded-lg text-capitalize"
					v-text="'Geri'"
					@click="returnBack"
				></v-btn>
				<v-btn
					:loading="loading"
					shaped
					color="button-color button-text--text"
					class="px-5 rounded-lg text-capitalize"
					v-text="'Telefon ile doğrula'"
					@click="continueProcess"
				></v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
import { carPartType, issueType, wizardStep, locationScheduleType } from '@/common/enums';
import SmsApprove from '@/components/enduser/wizard/SmsApprove.vue';
import contactValidationMixin from '@/mixins/validations/carWizard/contactValidationMixin';
import { CHANGE_WIZARD_STEP, SAVE_CONTACT, SET_CITY_LIST, SET_LOCATION_LIST } from '@/store/modules/carWizard.module';
import VueRecaptcha from 'vue-recaptcha';
import { validationMixin } from 'vuelidate';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'Contact',
	props: {
		smSize: {
			type: Number,
			default: 12,
			required: false,
		},
	},
	mixins: [validationMixin, contactValidationMixin],
	components: {
		SmsApprove,
		VueRecaptcha,
	},
	data() {
		return {
			wizardStep,
			carPartType,
			issueType,
			locationScheduleType,
			loading: false,
			captchaValid: false,
			contact: {
				phoneNumber: null,
				email: null,
				name: null,
				cityId: null,
				kvkkApproved: false,
				contactApproved: false,
				token: null,
				locationId: null,
			},
			timeOut: null,
		};
	},
	computed: {
		...mapGetters(['getCities', 'darkTemplate', 'getSite', 'getLocationList']),
		smsTimeOut() {
			return this.timeOut;
		},

		captchaSiteKey() {
			return process.env.VUE_APP_RECAPTCHA_SITE_KEY;
		},
	},
	created() {
		this.$nextTick(() => {
			this.setCityList();
		});
		this.$nextTick(() => {
			this.setLocationList();
		});
	},
	methods: {
		...mapActions({
			changeWizardStep: CHANGE_WIZARD_STEP,
			saveContactAndSendSmsValidationCode: SAVE_CONTACT,
			setCityList: SET_CITY_LIST,
			setLocationList: SET_LOCATION_LIST,
		}),
		onExpiredCaptcha() {
			this.captchaValid = false;
			this.$refs.recaptcha.reset();
		},
		onVerifyCaptcha(recaptchaToken) {
			this.contact.token = recaptchaToken;
			this.captchaValid = true;
		},
		continueProcess() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}

			this.loading = true;
			this.saveContactAndSendSmsValidationCode(this.contact)
				.then((response) => {
					this.timeOut = response.smsCodeTimeOut;
				})
				.finally(() => {
					this.loading = false;
					this.$refs.recaptcha.reset();
				});
		},
		returnBack() {
			this.changeWizardStep(this.getSite.isDamageActive ? wizardStep.DAMAGE : wizardStep.CAR_INFO);
			this.$vuetify.goTo(0);
		},
	},
};
</script>
<style lang="scss" scoped></style>
