import { required } from 'vuelidate/lib/validators';

const modelValidationMixin = {
	validations: {
		carInfo: {
			year: {
				required,
			},
			brand: {
				required,
			},
		},
		listing: {
			brand: {
				required,
			},
			model: {
				required,
			},
		},
	},
	methods: {},
};

export default modelValidationMixin;
