/* eslint-disable no-unused-expressions */
import { email, helpers, required, sameAs } from 'vuelidate/lib/validators';

const alphabeticAndSpace = helpers.regex('alphabeticAndSpace', /^[a-zA-Z ğüşöçİĞÜŞÖÇı]+$/);

const contactFormValidationMixin = {
	validations: {
		captchaValid: {
			required,
			sameAs: sameAs(() => true),
		},
		contact: {
			name: {
				alphabeticAndSpace,
				required,
			},
			eMail: {
				required,
				email,
			},
			subject: {
				required,
			},
			kvkkApproved: {
				required,
				sameAs: sameAs(() => true),
			},
		},
	},
	methods: {
		// validation methods for this page
		emailErrors() {
			const errors = [];
			const { $dirty, required, email } = this.$v.contact.eMail;
			if (!$dirty) return errors;
			!required && errors.push('Lütfen bir e-posta adresi girin.');
			!email && errors.push('Hatalı e-mail adresi girdiniz.');
			return errors;
		},
		nameErrors() {
			const errors = [];
			const { $dirty, required, alphabeticAndSpace } = this.$v.contact.name;
			if (!$dirty) return errors;
			!required && errors.push('Lütfen adınızı soyadınızı girin.');
			!alphabeticAndSpace && errors.push('Lütfen geçerli bir ad soyad girin.');
			return errors;
		},
		kvkkApprovedErrors() {
			const errors = [];
			const { $dirty, required, sameAs } = this.$v.contact.kvkkApproved;
			if (!$dirty) return errors;
			!required && errors.push('Lütfen aydınlatma metnini onaylayın.');
			!sameAs && errors.push('aydınlatma metnini onaylamanız gerekiyor.');
			return errors;
		},
	},
};

export default contactFormValidationMixin;
