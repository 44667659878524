import { wizardStep } from '@/common/enums';
import { mapActions, mapGetters } from 'vuex';
import { RESET_STATE } from '@/store/modules/carWizard.module';
import Info from '@/components/enduser/wizard/Info.vue';
import Damage from '@/components/enduser/wizard/Damage.vue';
import Contact from '@/components/enduser/wizard/Contact.vue';
import Offer from '@/components/enduser/wizard/Offer.vue';
import Complete from '@/components/enduser/wizard/Complete.vue';

const wizardMixin = {
	components: {
		Info,
		Damage,
		Contact,
		Offer,
		Complete,
	},
	data() {
		return {
			tab: null,
			wizardStep,
		};
	},
	computed: {
		...mapGetters(['getWizardStep', 'getSite']),
		pageSubTitle() {
			return this.getWizardStep !== wizardStep.COMPLETE
				? 'Fiyat, verdiğiniz bilgilere göre belirlenecektir. Aracınız ekspertiz noktasında kontrol edilecek ve bilgilere uygunluğu incelenecektir.'
				: 'Verdiğimiz teklife ilişkin araçla ilgili konuşmak üzere en kısa sürede sizi arayacağız. Bizi tercih ettiğiniz için teşekkür ederiz.';
		},
	},
	created() {
		this.$nextTick(() => {
			const activeStep = this.getWizardStep - 2;
			if (activeStep === 3) {
				this.resetState().then(() => {
					this.$router.push({
						path: '/',
					});
				});
				return;
			}
			this.tab = activeStep;

			this.setDocumentTitle(activeStep);
		});
	},
	methods: {
		...mapActions({
			resetState: RESET_STATE,
		}),
		setDocumentTitle(step) {
			let title = 'Teklif al';
			if (step === 0) {
				title = 'Araç Bilgileri';
			} else if (step === 1) {
				title = 'Hasar Bilgileri';
			} else if (step === 2) {
				title = 'İletişim Bilgileri';
			} else if (step === 3) {
				title = 'SMS Doğrulama';
			} else if (step === 4) {
				title = 'Teklifimiz';
			} else if (step === 5) {
				title = 'Teklif Onayı';
			}
			document.title = `${this.getSite?.companyName} | ${title}`;
		},
		replaceRoute(step) {
			let path = '/';
			if (step === 0) {
				path = 'arac-bilgileri';
			} else if (step === 1) {
				path = 'hasar-bilgileri';
			} else if (step === 2) {
				path = 'iletisim-bilgileri';
			} else if (step === 3) {
				path = 'sms-dogrulama';
			} else if (step === 4) {
				path = 'teklifimiz';
			} else if (step === 5) {
				path = 'teklif-onay';
			}

			this.$router.replace({
				name: 'get-offer',
				params: {
					step: path,
				},
			});
		},
	},
	watch: {
		getWizardStep: {
			handler(step) {
				const nextStep = step - 2;
				this.tab = nextStep;
				this.replaceRoute(nextStep);
				this.setDocumentTitle(nextStep);
			},
		},
	},
};

export default wizardMixin;
