const carListMixin = {
	methods: {
		createURLParams(car) {
			const params = { id: car.id };

			if (car.url) {
				params.url = car.url;
			}

			return params;
		},
	},
};

export default carListMixin;
