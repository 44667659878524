import BlueOne from '@/views/enduser/pages/blue-one/Index.vue';
import DarkOne from '@/views/enduser/pages/dark-one/Index.vue';

const templateMixin = {
	components: {
		BlueOne,
		DarkOne,
	},
};

export default templateMixin;
