<template>
	<v-card class="wizard-card car-wizard-info" elevation="0">
		<v-card-text class="rounded pa-10">
			<v-row dense>
				<v-col cols="12" :sm="smSize" :md="mdSize" class="px-7">
					<v-subheader>Model Yılı * </v-subheader>
					<v-select
						label="Year"
						solo
						item-text="value"
						item-value="id"
						hide-details
						flat
						dense
						class="rounded-lg"
						v-model="$v.carInfo.year.$model"
						:items="getYears"
						@change="yearChanged"
					>
					</v-select>
				</v-col>
				<v-col cols="12" :sm="smSize" :md="mdSize" class="px-7">
					<v-subheader>Marka *</v-subheader>
					<v-select
						label="Brand"
						solo
						item-text="value"
						item-value="id"
						hide-details
						flat
						dense
						class="rounded-lg"
						v-model="$v.carInfo.brand.$model"
						:items="getBrands"
						:returnObject="true"
						:disabled="brandIsDisabled"
						@change="brandChanged"
					></v-select>
				</v-col>
				<v-col cols="12" :sm="smSize" :md="mdSize" class="px-7">
					<v-subheader>Model *</v-subheader>
					<v-select
						label="Model"
						solo
						item-text="value"
						item-value="id"
						hide-details="auto"
						flat
						dense
						class="rounded-lg"
						:items="getModels"
						:returnObject="true"
						:disabled="modelIsDisabled"
						:error-messages="modelErrors()"
						v-model="$v.carInfo.model.$model"
						@change="modelChanged"
					></v-select>
				</v-col>
				<v-col cols="12" :sm="smSize" :md="mdSize" class="px-7">
					<v-subheader>Kasa Tipi *</v-subheader>
					<v-select
						label="Kasa Tipi *"
						solo
						item-text="value"
						item-value="id"
						hide-details="auto"
						flat
						dense
						class="rounded-lg"
						:items="getBodyTypes"
						:returnObject="true"
						:disabled="bodyTypeIsDisabled"
						:error-messages="bodyTypeErrors()"
						v-model="$v.carInfo.bodyType.$model"
						@change="bodyTypeChanged"
					>
					</v-select>
				</v-col>
				<v-col cols="12" :sm="smSize" :md="mdSize" class="px-7">
					<v-subheader>Vites *</v-subheader>
					<v-select
						label="Vites"
						solo
						item-text="value"
						item-value="id"
						hide-details="auto"
						flat
						dense
						class="rounded-lg"
						:items="getTransmissionTypes"
						:returnObject="true"
						:disabled="transmissionTypeIsDisabled"
						:error-messages="transmissionTypeErrors()"
						v-model="$v.carInfo.transmissionType.$model"
						@change="transmissionTypeChanged"
					></v-select>
				</v-col>
				<v-col cols="12" :sm="smSize" :md="mdSize" class="px-7">
					<v-subheader>Yakıt *</v-subheader>
					<v-select
						label="Yakıt"
						solo
						item-text="value"
						item-value="id"
						hide-details="auto"
						flat
						dense
						class="rounded-lg"
						:items="getFuelTypes"
						:returnObject="true"
						:disabled="fuelTypeIsDisabled"
						:error-messages="fuelTypeErrors()"
						v-model="$v.carInfo.fuelType.$model"
						@change="fuelTypeChanged"
					>
					</v-select>
				</v-col>
				<v-col cols="12" :sm="smSize" :md="mdSize" class="px-7">
					<v-subheader>Versiyon *</v-subheader>
					<v-select
						label="Versiyon"
						solo
						item-text="value"
						item-value="id"
						hide-details="auto"
						flat
						dense
						class="rounded-lg"
						:items="getVersions"
						:returnObject="true"
						:disabled="versionIsDisabled"
						:error-messages="versionErrors()"
						v-model="$v.carInfo.version.$model"
					></v-select>
				</v-col>
				<v-col cols="12" :sm="smSize" :md="mdSize" class="px-7">
					<v-subheader>Kilometre *</v-subheader>
					<v-text-field
						inputmode="numeric"
						label="Kilometre"
						solo
						hide-details="auto"
						flat
						dense
						class="rounded-lg"
						:error-messages="kilometersErrors()"
						v-model="$v.carInfo.kilometers.$model"
						@keyup="kilometersKeyPress"
					></v-text-field>
				</v-col>
				<v-col cols="12" :sm="smSize" :md="mdSize" class="px-7">
					<v-subheader class="leadgen-combobox-title"
						>Renk <template v-if="getSite.isColorMandatory">*</template></v-subheader
					>
					<v-select
						label="Renk"
						solo
						item-text="value"
						item-value="id"
						hide-details="auto"
						flat
						dense
						class="rounded-lg"
						:items="getColors"
						:returnObject="true"
						:clearable="!getSite.isColorMandatory"
						:error-messages="colorErrors()"
						v-model="$v.carInfo.color.$model"
					></v-select>
				</v-col>
				<template v-if="getSite.isExchangeActive">
					<v-col cols="12" :sm="smSize" :md="mdSize" class="px-7">
						<v-checkbox
							:color="darkTemplate ? 'template-color darken-2' : 'template-color'"
							:dark="darkTemplate"
							class="chk-swap"
							hide-details="auto"
							dense
							v-model="carInfo.isExchangeRequest"
							label="Takas İstiyorum"
						>
							<template v-slot:label>
								<div :class="darkTemplate ? 'template-text--text' : ' template-color--text'">
									Takas İstiyorum
								</div>
							</template>
						</v-checkbox>
					</v-col>
					<v-col cols="12" class="px-7" v-if="isExchangeRequest">
						<v-subheader class="leadgen-combobox-title">Notunuz</v-subheader>
						<v-textarea
							solo
							dense
							flat
							hide-details="auto"
							no-resize
							counter="500"
							placeholder="Takas istediğiniz aracı tanımlayın"
							class="rounded-lg"
							v-model="$v.carInfo.exchangeDescription.$model"
							:error="$v.carInfo.exchangeDescription.$error"
							:error-messages="exchangeDescriptions()"
						></v-textarea>
					</v-col>
				</template>
			</v-row>
		</v-card-text>
		<v-card-actions class="d-flex justify-center py-5">
			<v-btn
				:loading="loading"
				shaped
				color="button-color button-text--text"
				class="px-5 rounded-lg text-capitalize"
				v-text="'Devam Et'"
				@click="continueProcess"
			></v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { wizardStep } from '@/common/enums';
import { mapActions, mapGetters } from 'vuex';
import { GET_WIZARD_DATA, SET_CAR_WIZARD, CREATE_CAR_WIZARD, FETCH_COLORS } from '@/store/modules/carWizard.module';
import { validationMixin } from 'vuelidate';
import infoValidationMixin from '@/mixins/validations/carWizard/infoValidationMixin';

export default {
	name: 'Info',
	props: {
		mdSize: {
			type: Number,
			default: 12,
			required: false,
		},
		smSize: {
			type: Number,
			default: 12,
			required: false,
		},
	},
	mixins: [validationMixin, infoValidationMixin],
	data() {
		return {
			carInfo: {
				year: null,
				brand: null,
				model: null,
				bodyType: null,
				transmissionType: null,
				fuelType: null,
				version: null,
				kilometers: null,
				color: null,
				isExchangeRequest: false,
				exchangeDescription: null,
			},
			nf: new Intl.NumberFormat('tr-TR'),
			loading: false,
		};
	},
	computed: {
		...mapGetters([
			'getSite',
			'darkTemplate',
			'getYears',
			'getBrands',
			'getModels',
			'getBodyTypes',
			'getTransmissionTypes',
			'getFuelTypes',
			'getVersions',
			'getColors',
			'getWizardCarInfo',
		]),
		brandIsDisabled() {
			return this.getBrands.length === 0;
		},
		modelIsDisabled() {
			return this.getModels.length === 0;
		},
		bodyTypeIsDisabled() {
			return this.getBodyTypes.length === 0;
		},
		transmissionTypeIsDisabled() {
			return this.getTransmissionTypes.length === 0;
		},
		fuelTypeIsDisabled() {
			return this.getFuelTypes.length === 0;
		},
		versionIsDisabled() {
			return this.getVersions.length === 0;
		},
		isExchangeRequest() {
			return this.carInfo.isExchangeRequest;
		},
	},
	created() {
		this.$nextTick(() => {
			if (this.getColors.length === 0) this.fetchColors();

			this.carInfo = {
				year: this.getWizardCarInfo.year,
				brand: this.getWizardCarInfo.brand,
				model: this.getWizardCarInfo.model,
				bodyType: this.getWizardCarInfo.bodyType,
				transmissionType: this.getWizardCarInfo.transmissionType,
				fuelType: this.getWizardCarInfo.fuelType,
				version: this.getWizardCarInfo.version,
				kilometers: this.getWizardCarInfo.kilometers,
				color: this.getWizardCarInfo.color,
				isExchangeRequest: this.getWizardCarInfo.isExchangeRequest,
				exchangeDescription: this.getWizardCarInfo.exchangeDescription,
			};

			const payload = {
				year: this.carInfo.year,
				brandId: this.carInfo.brand.id,
			};

			if (this.carInfo.model === null) this.getWizardData({ payload, fillArrayField: 'models' });
		});
	},
	methods: {
		...mapActions({
			setCarWizard: SET_CAR_WIZARD,
			createCarWizard: CREATE_CAR_WIZARD,
			getWizardData: GET_WIZARD_DATA,
			fetchColors: FETCH_COLORS,
		}),
		yearChanged(year) {
			const payload = {
				year,
			};
			this.getWizardData({ payload, fillArrayField: 'brands' }).then(() => {
				this.brandChanged();
			});
		},
		brandChanged() {
			const payload = {
				year: this.carInfo.year,
				brandId: this.carInfo.brand.id,
			};

			this.getWizardData({ payload, fillArrayField: 'models' }).then(() => {
				if (!this.getModels.some((x) => x.id === this.carInfo.model?.id)) {
					this.carInfo.model = null;
					return;
				}

				this.modelChanged();
			});
		},
		modelChanged() {
			const payload = {
				year: this.carInfo.year,
				brandId: this.carInfo.brand.id,
				modelId: this.carInfo.model.id,
			};
			this.getWizardData({ payload, fillArrayField: 'bodyTypes' }).then(() => {
				if (!this.getBodyTypes.some((x) => x.id === this.carInfo.bodyType?.id)) {
					if (this.getBodyTypes.length === 1) {
						// eslint-disable-next-line prefer-destructuring
						this.carInfo.bodyType = this.getBodyTypes[0];
					} else {
						this.carInfo.bodyType = null;
						return;
					}
				}

				this.bodyTypeChanged();
			});
		},
		bodyTypeChanged() {
			const payload = {
				year: this.carInfo.year,
				brandId: this.carInfo.brand.id,
				modelId: this.carInfo.model.id,
				bodyTypeId: this.carInfo.bodyType.id,
			};
			this.getWizardData({ payload, fillArrayField: 'transmissionTypes' }).then(() => {
				if (!this.getTransmissionTypes.some((x) => x.id === this.carInfo.transmissionType?.id)) {
					if (this.getTransmissionTypes.length === 1)
						// eslint-disable-next-line prefer-destructuring
						this.carInfo.transmissionType = this.getTransmissionTypes[0];
					else {
						this.carInfo.transmissionType = null;
						return;
					}
				}

				this.transmissionTypeChanged();
			});
		},
		transmissionTypeChanged() {
			const payload = {
				year: this.carInfo.year,
				brandId: this.carInfo.brand.id,
				modelId: this.carInfo.model.id,
				bodyTypeId: this.carInfo.bodyType.id,
				transmissionTypeId: this.carInfo.transmissionType.id,
			};
			this.getWizardData({ payload, fillArrayField: 'fuelTypes' }).then(() => {
				if (!this.getFuelTypes.some((x) => x.id === this.carInfo.fuelType?.id)) {
					if (this.getFuelTypes.length === 1) {
						// eslint-disable-next-line prefer-destructuring
						this.carInfo.fuelType = this.getFuelTypes[0];
					} else {
						this.carInfo.fuelType = null;
						return;
					}
				}

				this.fuelTypeChanged();
			});
		},
		fuelTypeChanged() {
			const payload = {
				year: this.carInfo.year,
				brandId: this.carInfo.brand.id,
				modelId: this.carInfo.model.id,
				bodyTypeId: this.carInfo.bodyType.id,
				transmissionTypeId: this.carInfo.transmissionType.id,
				fuelTypeId: this.carInfo.fuelType.id,
			};
			this.getWizardData({ payload, fillArrayField: 'versions' }).then(() => {
				if (!this.getVersions.some((x) => x.id === this.carInfo.version?.id)) {
					if (this.getVersions.length === 1) {
						// eslint-disable-next-line prefer-destructuring
						this.carInfo.version = this.getVersions[0];
					} else {
						this.carInfo.version = null;
					}
				}
			});
		},
		kilometersKeyPress($event) {
			const text = $event.target.value.replace(/[^0-9]/g, '');
			this.carInfo.kilometers = this.nf.format(text);
			return true;
		},
		continueProcess() {
			this.$v.$touch();
			if (this.$v.carInfo.$invalid) {
				return;
			}

			const { carInfo } = this;
			// TODO : kilometers type should be decimal on the db?
			if (carInfo.kilometers !== null && this.carInfo.kilometers !== '')
				carInfo.kilometers = carInfo.kilometers.replace(/[^0-9]/g, '');

			this.loading = true;
			this.createCarWizard({
				carInfo: { ...this.carInfo },
				step: this.getSite.isDamageActive ? wizardStep.DAMAGE : wizardStep.CONTACT,
			})
				.then(() => {
					this.$vuetify.goTo(0);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
	watch: {
		isExchangeRequest: {
			handler(active) {
				if (!active) {
					this.carInfo.exchangeDescription = null;
				}
			},
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep {
	.dark-one {
		.chk-swap {
			label {
				color: var(--v-template-text-base) !important;
			}
		}
	}
}
</style>
