<template>
	<div id="get-offer" class="car-wizard">
		<div class="text-subtitle-1 text-center mb-5 px-5">{{ pageSubTitle }}</div>
		<v-layout wrap align-center justify-center v-if="getWizardStep !== wizardStep.COMPLETE">
			<v-flex xs12 sm12 md7 align-self-center>
				<v-tabs v-model="tab" fixed-tabs color="template-color" mobile-breakpoint="sm">
					<v-tab key="info" :disabled="getWizardStep != wizardStep.CAR_INFO">Araç Seçimi</v-tab>
					<v-tab key="damage" :disabled="getWizardStep != wizardStep.DAMAGE" v-show="getSite.isDamageActive"
						>Hasar Bilgileri</v-tab
					>
					<v-tab
						key="contact"
						:disabled="getWizardStep != wizardStep.CONTACT || getWizardStep != wizardStep.SMS_APPROVE"
						>İletişim</v-tab
					>
					<v-tab key="offer" :disabled="getWizardStep != wizardStep.OFFER">Teklif</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab" touchless>
					<v-tab-item key="info" transition="fade-transition" reverse-transition="fade-transition">
						<Info :md-size="4" :sm-size="6" v-if="getWizardStep === wizardStep.CAR_INFO" />
					</v-tab-item>
					<v-tab-item
						key="damage"
						transition="fade-transition"
						reverse-transition="fade-transition"
						v-show="getSite.isDamageActive"
					>
						<Damage :md-size="6" :sm-size="6" v-if="getWizardStep === wizardStep.DAMAGE" />
					</v-tab-item>
					<v-tab-item key="contact" transition="fade-transition" reverse-transition="fade-transition">
						<Contact
							:sm-size="6"
							v-if="getWizardStep === wizardStep.CONTACT || getWizardStep === wizardStep.SMS_APPROVE"
						/>
					</v-tab-item>
					<v-tab-item key="offer" transition="fade-transition" reverse-transition="fade-transition">
						<Offer v-if="getWizardStep === wizardStep.OFFER" />
					</v-tab-item>
				</v-tabs-items>
			</v-flex>
		</v-layout>
		<Complete v-if="getWizardStep === wizardStep.COMPLETE" />
	</div>
</template>

<script>
import wizardMixin from '@/mixins/wizardMixin';

export default {
	name: 'Wizard',
	mixins: [wizardMixin],
};
</script>
<style lang="scss">
.car-wizard {
	margin: 150px 0px 30px 0px;
}
</style>
