<template>
	<div class="carousel" v-if="localImages.length > 0" :style="style">
		<transition name="fade" v-if="loading">
			<div class="preloader">
				<div class="spinner"></div>
			</div>
		</transition>
		<div
			class="slides-container"
			:style="{ width: containerWidth + 'px', height: containerHeight + 'px' }"
			@mouseover="handleControlsHover"
			@mouseleave="handleControlsLeave"
			@touchstart="handleTouchStart"
			@touchmove="handleTouchMove"
			@touchend="handleTouchEnd"
		>
			<div class="slides" :style="{ transform: 'translateX(' + slideOffset + 'px)' }">
				<div
					v-for="(image, index) in localImages"
					:key="`banner_${index}`"
					class="slide"
					:style="{ width: containerWidth + 'px', height: slideHeight + 'px' }"
				>
					<a v-if="image.link !== null" :href="image.link ? image.link : null" target="_blank"></a>
					<img :src="getImageSource(image)" :style="{ height: slideHeight + 'px' }" />
					<div class="text-content" :style="{ color: image.color }" v-if="image.title || image.subTitle">
						<h3 class="slider-item-title">{{ image.title }}</h3>
						<div class="slider-item-subtitle">{{ image.subTitle }}</div>
					</div>
				</div>
			</div>
			<div class="carousel-controls" v-if="mergedOptions.arrows">
				<div class="prev" v-on:click="prevSlide()">&#8249;</div>
				<div class="next" v-on:click="nextSlide()">&#8250;</div>
			</div>
		</div>
		<div
			class="carousel-indicators"
			:class="{ 'carousel-indicators--outside': mergedOptions.showIndicatorOutside }"
			v-if="mergedOptions.dots"
		>
			<div
				v-for="(image, index) in localImages"
				:key="index"
				class="indicator"
				:class="{ active: index === currentSlide }"
				v-on:click="goToSlide(index)"
			></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Carousel',
	props: {
		images: {
			type: Array,
			required: true,
			default: () => [],
		},
		options: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			sourceImages: [...this.images],
			containerWidth: 0,
			containerHeight: 0,
			slideWidth: 0,
			slideHeight: 0,
			slideOffset: 0,
			currentSlide: 0,
			slideIntervalId: null,
			touchStartX: 0,
			touchEndX: 0,
			loading: true,
		};
	},
	computed: {
		localImages() {
			return this.sourceImages;
		},
		style() {
			return {
				'--v-carousel-padding': `${this.mergedOptions.showIndicatorOutside ? 35 : 0}px`,
				'--v-carousel-overflow': `${this.mergedOptions.showIndicatorOutside ? 'visible' : 'hidden'}`,
			};
		},
		mergedOptions() {
			return {
				dots: false,
				arrows: false,
				autoPlay: true,
				autoPlaySlideInterval: 5000,
				autoplayHoverPause: true,
				showIndicatorOutside: false,
				...this.options,
			};
		},
	},
	mounted() {
		this.initializeCarousel().finally(() => {
			setTimeout(() => {
				this.loading = false;
				this.startSlideInterval();
			}, 500);
		});
	},
	beforeUnmount() {
		this.stopSlideInterval();
	},
	methods: {
		handleOrientationChange() {
			this.handleResize();
		},
		getImageSource(image) {
			const isMobile = this.$vuetify.breakpoint.smAndDown;
			return isMobile ? image.mobileFile : image.file;
		},
		initializeCarousel() {
			return new Promise((resolve) => {
				if (this.localImages.length === 0) {
					resolve();
					return;
				}

				const firstImage = new Image();
				const isMobile = this.$vuetify.breakpoint.smAndDown;
				const imageUrl = isMobile ? this.localImages[0].mobileFile : this.localImages[0].file;

				firstImage.src = imageUrl;

				firstImage.onload = async () => {
					this.containerWidth = this.$el.offsetWidth;
					requestAnimationFrame(async () => {
						await this.setSlideDimensions(firstImage);
						resolve();
					});
				};
			});
		},
		setSlideDimensions(image) {
			return new Promise((resolve) => {
				const aspectRatio = image.height / image.width;
				this.slideHeight = this.containerWidth * aspectRatio;
				this.containerHeight = this.slideHeight;
				if (!image.width || !image.height) {
					this.containerHeight = 'auto';
					this.slideHeight = 'auto';
				}
				this.slideWidth = this.containerWidth;
				resolve();
			});
		},
		handleResize() {
			this.loading = true;

			setTimeout(() => {
				this.updateDimensions().then(() => {
					this.loading = false;
				});
			}, 500);
		},
		async updateDimensions() {
			return new Promise((resolve) => {
				const firstImage = new Image();
				const isMobile = this.$vuetify.breakpoint.smAndDown;
				const imageUrl = isMobile ? this.localImages[0].mobileFile : this.localImages[0].file;

				firstImage.src = imageUrl;

				firstImage.onload = async () => {
					this.containerWidth = this.$el.offsetWidth;
					requestAnimationFrame(async () => {
						await this.setSlideDimensions(firstImage);
						this.slideOffset = -this.currentSlide * this.slideWidth;
						resolve();
					});
				};
			});
		},
		goToSlide(index) {
			this.stopSlideInterval();
			this.currentSlide = index;
			this.slideOffset = -this.currentSlide * this.slideWidth;
			this.startSlideInterval();
		},
		nextSlide() {
			this.stopSlideInterval();
			this.currentSlide = (this.currentSlide + 1) % this.localImages.length;
			this.slideOffset = -this.currentSlide * this.slideWidth;
			this.startSlideInterval();
		},
		startSlideInterval() {
			if (!this.mergedOptions.autoPlay) return;
			this.slideIntervalId = setInterval(() => {
				this.nextSlide();
			}, this.mergedOptions.autoPlaySlideInterval);
		},
		stopSlideInterval() {
			clearInterval(this.slideIntervalId);
		},
		prevSlide() {
			this.stopSlideInterval();
			this.currentSlide = (this.currentSlide - 1 + this.localImages.length) % this.localImages.length;
			this.slideOffset = -this.currentSlide * this.slideWidth;
			this.startSlideInterval();
		},
		handleControlsHover() {
			if (this.mergedOptions.autoplayHoverPause) this.stopSlideInterval();
		},
		handleControlsLeave() {
			if (this.mergedOptions.autoplayHoverPause) this.startSlideInterval();
		},
		handleTouchStart(e) {
			this.touchStartX = e.touches[0].clientX;
		},
		handleTouchMove(e) {
			e.preventDefault();
		},
		handleTouchEnd(e) {
			this.touchEndX = e.changedTouches[0].clientX;
			const touchDiff = this.touchStartX - this.touchEndX;

			if (Math.abs(touchDiff) > 50) {
				if (touchDiff > 0) {
					this.nextSlide();
				} else {
					this.prevSlide();
				}
			}
		},
	},
	watch: {
		'$vuetify.breakpoint.smAndDown': {
			handler() {
				this.handleResize();
			},
		},
	},
};
</script>
<style lang="scss" scoped>
.carousel {
	position: relative;
	overflow: var(--v-carousel-overflow);
	padding-bottom: var(--v-carousel-padding);
	.slides-container {
		position: relative;
		overflow: hidden;
		white-space: nowrap;
		transition: height 0.2s ease-out;

		.slides {
			display: inline-block;
			position: relative;
			transition: transform 0.2s ease-out;

			.slide {
				display: inline-block;
				position: relative;

				img {
					object-fit: cover;
					width: 100%;
				}

				.text-content {
					position: absolute;
					background-color: #29292933;
					-webkit-backdrop-filter: blur(5px);
					backdrop-filter: blur(5px);
					border-radius: 5px;
					overflow: hidden;
					top: 50%;
					transform: translateY(-50%);
					left: 5%;
					padding: 10px;
					@media only screen and (max-width: 600px) {
						right: 5%;
						display: flex;
						flex-direction: column;
						flex-wrap: wrap;
					}

					.slider-item-title {
						color: inherit;
						font-size: 2.5vw;
						font-weight: bold;
						line-height: normal;

						@media only screen and (max-width: 600px) {
							font-size: 1.5rem !important;
						}
					}

					.slider-item-subtitle {
						color: inherit;
						font-size: 1.2vw !important;

						@media only screen and (max-width: 600px) {
							font-size: 3.2vw !important;
						}
					}
				}
			}

			a {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
			}
		}

		.carousel-controls {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			z-index: 1;
			width: 100%;
			height: 100%;

			div {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				font-size: 40px;
				font-weight: bold;
				color: #fff;
				text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
				cursor: pointer;

				&.prev {
					left: 20px;
				}
				&.next {
					right: 20px;
				}
			}
		}
	}

	.carousel-indicators {
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
		display: flex;
		justify-content: center;

		&--outside {
			bottom: 10px !important;

			.indicator {
				background-color: var(--v-secondary-darken1) !important;

				&.active {
					background-color: var(--v-secondary-darken2) !important;
					box-shadow: 0 0 0 3px var(--v-secondary-base) !important;
				}
			}
		}

		.indicator {
			width: 12px;
			height: 12px;
			margin: 0 8px;
			border-radius: 50%;
			background-color: rgba(255, 255, 255, 0.3);
			cursor: pointer;
			transition: all 0.2s ease-out;

			&.active {
				background-color: #fff;
				box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
				width: 15px;
				height: 15px;
				margin: 0 5px;
			}
		}

		@media (max-width: 768px) {
			.indicator {
				width: 8px;
				height: 8px;
				margin: 0 4px;

				&.active {
					width: 10px;
					height: 10px;
					margin: 0 3px;
				}
			}
		}
	}

	.preloader {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #fff;
		z-index: 9999;
		opacity: 1;
		transition: opacity 1s;

		&.fade-out {
			opacity: 0;
		}

		.spinner {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 60px;
			height: 60px;
			margin: -30px 0 0 -30px;
			border: 5px solid var(--v-template-color-base);
			border-top-color: var(--v-template-color-darken3);
			border-radius: 100%;
			animation: spin 1s ease-in-out infinite;
		}

		@keyframes spin {
			to {
				transform: rotate(360deg);
			}
		}
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s;
	}

	.fade-enter,
	.fade-leave-active {
		opacity: 0;
	}
}
</style>
