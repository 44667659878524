const scrollRouterMixin = {
	mounted() {
		// const options = {
		// 	root: null,
		// 	rootMargin: '0px',
		// 	threshold: 1,
		// };
		// const observer = new IntersectionObserver(this.handleIntersect, options);
		// document.querySelectorAll('.page-section').forEach((section) => {
		// 	observer.observe(section);
		// });
	},
	methods: {
		handleIntersect(entries) {
			entries.forEach((entry) => {
				if (entry.isIntersecting && entry.intersectionRatio === 1) {
					const urlText = this.$router.getRoutes().find((route) => route.name === entry.target.id)?.path;
					window.history.replaceState(null, null, urlText);
				}
			});
		},
	},
};

export default scrollRouterMixin;
