<template>
	<div class="bottom-line-capsul" :class="{ desktop: $vuetify.breakpoint.mdAndUp }">
		<div class="dark-side" :class="{ 'dark-side--small': $vuetify.breakpoint.smAndDown }">
			<div class="light-side" v-if="$vuetify.breakpoint.mdAndUp"></div>
			<v-layout fill-height class="d-flex align-center">
				<div>
					<v-img
						contain
						class="mt-4 ml-6"
						:width="$vuetify.breakpoint.smAndDown ? '130' : '240'"
						height="114"
						:src="`${$assetPath}bottom-line-car.png`"
					></v-img>
				</div>
				<v-flex md3 class="px-5 template-text--text" :class="{ 'slogan-text': $vuetify.breakpoint.smAndDown }">
					{{ getSite.slogan }}
				</v-flex>
			</v-layout>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'BottomLine',
	computed: {
		...mapGetters(['getSite']),
	},
};
</script>

<style lang="scss" scoped>
#home {
	.bottom-line-capsul {
		&.desktop {
			position: absolute;
			bottom: 0px;

			.dark-side {
				position: absolute;
			}
		}

		width: 100%;

		.dark-side {
			border-radius: 15px 0px 0px 0px;
			bottom: 0px;
			left: -200px;
			right: 0px;
			min-width: 100%;
			width: 100%;
			height: 160px;
			background-color: var(--v-template-color-darken1);

			.slogan-text {
				line-height: 15px;
			}

			&--small {
				left: 0px;
				border-radius: 0px;
				right: 0px;
			}
		}
		.light-side {
			border-radius: 15px 0px 0px 0px;
			position: absolute;
			background-color: var(--v-template-color-base);
			width: 200px;
			bottom: 0px;
		}
	}
}
</style>
