<template>
	<div id="get-offer" class="car-wizard">
		<div class="text-subtitle-1 text-center mb-10 px-5">{{ pageSubTitle }}</div>

		<v-layout wrap justify-center v-if="getWizardStep !== wizardStep.COMPLETE">
			<v-flex xs12 sm12 md2>
				<v-tabs
					hide-slider
					grow
					v-model="tab"
					:vertical="$vuetify.breakpoint.mdAndUp"
					light
					color="template-color"
					active-class="tab-active"
					mobile-breakpoint="sm"
				>
					<v-tab key="info" class="text-capitalize justify-start mr-3" :disabled="getWizardStep != wizardStep.CAR_INFO">
						<div class="counter">1</div>
						Araç Seçimi
					</v-tab>
					<v-tab
						key="damage"
						class="text-capitalize justify-start mr-3"
						:disabled="getWizardStep != wizardStep.DAMAGE"
						v-show="getSite.isDamageActive"
					>
						<div class="counter">2</div>
						Hasar Bilgileri
					</v-tab>
					<v-tab
						key="contact"
						class="text-capitalize justify-start mr-3"
						:disabled="!(getWizardStep === wizardStep.CONTACT || getWizardStep === wizardStep.SMS_APPROVE)"
					>
						<div class="counter">{{ getSite.isDamageActive ? 3 : 2 }}</div>
						İletişim
					</v-tab>
					<v-tab key="offer" class="text-capitalize justify-start" :disabled="getWizardStep != wizardStep.OFFER">
						<div class="counter">{{ getSite.isDamageActive ? 4 : 3 }}</div>
						Teklif
					</v-tab>
				</v-tabs>
			</v-flex>
			<v-flex xs12 sm12 md3 offset-md1>
				<v-tabs-items v-model="tab" light touchless>
					<v-tab-item key="info" transition="fade-transition" reverse-transition="fade-transition">
						<Info v-if="getWizardStep === wizardStep.CAR_INFO" />
					</v-tab-item>
					<v-tab-item
						key="damage"
						transition="fade-transition"
						reverse-transition="fade-transition"
						v-show="getSite.isDamageActive"
					>
						<Damage v-if="getWizardStep === wizardStep.DAMAGE" />
					</v-tab-item>
					<v-tab-item key="contact" transition="fade-transition" reverse-transition="fade-transition">
						<Contact v-if="getWizardStep === wizardStep.CONTACT || getWizardStep === wizardStep.SMS_APPROVE" />
					</v-tab-item>
					<v-tab-item key="offer" transition="fade" reverse-transition="fade-transition">
						<Offer v-if="getWizardStep === wizardStep.OFFER" />
					</v-tab-item>
				</v-tabs-items>
			</v-flex>
		</v-layout>
		<Complete v-if="getWizardStep === wizardStep.COMPLETE" />
	</div>
</template>

<script>
import wizardMixin from '@/mixins/wizardMixin';

export default {
	name: 'Wizard',
	mixins: [wizardMixin],
};
</script>

<style lang="scss" scoped>
.car-wizard {
	margin: 150px 0px 30px 0px;

	.v-tab {
		&:hover {
			background-color: none !important;
		}
		margin-bottom: 60px;
		border-bottom: 3px solid #bbbbbb;
		padding: 0px !important;
		height: 30px !important;

		.counter {
			background-color: var(--v-template-color-base) !important;
			color: var(--v-contrast-color);
			line-height: 30px;
			width: 30px;
			height: 30px;
			margin-right: 15px;
		}
	}
	.tab-active {
		background-color: none !important;
		border-bottom: 3px solid var(--v-template-color-base);
		color: #272727 !important;
		font-weight: bold;
	}
}
</style>
