<template>
	<div class="text-center px-10">
		<v-row class="d-flex align-center">
			<v-col>
				<div class="d-flex" :class="$vuetify.breakpoint.smAndDown ? 'justify-center' : 'justify-end'">
					<div>
						<div
							class="text-center font-weight-bold template-color--text"
							:class="$vuetify.breakpoint.smAndDown ? 'text-h5' : 'text-h3'"
						>
							Sizinle en kısa sürede<br />
							iletişime geçeceğiz.
						</div>
						<v-spacer class="my-2"></v-spacer>
						<div v-if="getTrackingNumber" class="d-flex align-center justify-center ">
							<span class="template-color--text text-body-1 font-weight-bold mr-1">Takip numaranız : </span>
							<span class="text-h5 font-weight-bold">{{ getTrackingNumber }}</span>
						</div>
					</div>
				</div>
			</v-col>
			<v-col>
				<div class="d-flex justify-start">
					<object :data="`${$assetPath}complete.svg`" width="287" height="460"></object>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-btn
					large
					shaped
					color="button-color button-text--text"
					class="rounded-lg text-capitalize"
					v-text="'Ana Sayfaya Dön'"
					@click="resetProcess"
				></v-btn>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { RESET_STATE } from '@/store/modules/carWizard.module';

export default {
	name: 'Offer',
	components: {},
	data() {
		return {};
	},
	computed: {
		...mapGetters(['getTrackingNumber']),
	},
	created() {},
	methods: {
		...mapActions({
			resetState: RESET_STATE,
		}),
		resetProcess() {
			this.resetState();
			this.$vuetify.goTo(0);
			this.$router.push({
				name: 'home',
			});
		},
	},
};
</script>
<style lang="scss" scoped></style>
