<template>
	<v-container id="quickLink" class="my-5" v-if="getSite">
		<v-row justify="center">
			<v-col cols="12" md="3" lg="2" v-for="(item, index) in getSite.quickLinks" :key="`link-${index}`">
				<div class="d-flex justify-center align-center custom-link">
					<a :href="item.link" target="_blank"></a>
					<v-img :src="item.icon" :lazy-src="item.icon" max-height="40" max-width="40"></v-img>
					<div class="ml-5 font-weight-bold">{{ item.title }}</div>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'QuickLink',
	data() {
		return {};
	},
	computed: {
		...mapGetters(['getSite']),
	},
};
</script>

<style lang="scss" scoped>
.get-offer-btn {
	background: var(--v-template-color-darken2) !important;
}
.custom-link {
	background: -webkit-gradient(
		linear,
		left bottom,
		left top,
		from(var(--v-template-color-base)),
		to(var(--v-template-color-darken1))
	);
	background: linear-gradient(0deg, var(--v-template-color-base) 0%, var(--v-template-color-darken1) 100%);
	color: var(--v-template-text-base);
	padding: 10px;
	border-radius: 10px;
	box-shadow: 0px 3px 5px var(--v-template-color-darken2);
	font-size: 15px;
	line-height: 15px;
	height: 70px;
	position: relative;

	div {
		max-width: 150px;
	}

	a {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}
</style>
