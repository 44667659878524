<template>
	<div>
		<top-bar />
		<wizard v-if="getWizardStep !== wizardStep.MODEL" />
		<home v-if="getWizardStep === wizardStep.MODEL" />
		<QuickLinks v-if="isQuickLinksShow && getWizardStep === wizardStep.MODEL" />
		<search />
		<best-listing-carousel v-if="hasListing" />
		<why-us v-if="getWizardStep === wizardStep.MODEL && isWhyUseShow" />
		<faq v-if="getWizardStep === wizardStep.MODEL && isFaqShow" />
		<WorkHours v-if="isWorkHoursShow" page="other" />
		<contact />
		<footer-bar />
	</div>
</template>

<script>
import { wizardStep } from '@/common/enums';
import FooterBar from '@/views/_shared/layout/enduser/Footer.vue';
import TopBar from '@/views/_shared/layout/enduser/TopBar.vue';
import Contact from '@/views/enduser/pages/_shared/Contact.vue';
import Faq from '@/views/enduser/pages/_shared/Faq.vue';
import QuickLinks from '@/views/enduser/pages/_shared/QuickLinks.vue';
import WorkHours from '@/views/enduser/pages/_shared/WorkHours.vue';
import { mapGetters } from 'vuex';
import scrollRouterMixin from '@/mixins/scrollRouterMixin';
import Search from '../../../listing/_shared/search.vue';
import BestListingCarousel from '../_shared/BestListingCarousel.vue';
import Home from './Home.vue';
import WhyUs from './WhyUs.vue';
import Wizard from './Wizard.vue';

export default {
	name: 'DarkOne',
	mixins: [scrollRouterMixin],
	components: {
		TopBar,
		Home,
		FooterBar,
		Faq,
		WorkHours,
		Contact,
		WhyUs,
		Wizard,
		BestListingCarousel,
		Search,
		QuickLinks,
	},
	data() {
		return {
			wizardStep,
		};
	},
	computed: {
		...mapGetters(['getWizardStep', 'getSite', 'hasListing']),
		isFaqShow() {
			return this.getSite?.faq.length > 0;
		},
		isWorkHoursShow() {
			return this.getSite?.workHours.length > 0;
		},
		isWhyUseShow() {
			return this.getSite?.businessStep.length > 0;
		},
		isQuickLinksShow() {
			return this.getSite?.quickLinks.length > 0;
		},
	},
};
</script>

<style lang="scss" scoped></style>
