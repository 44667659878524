/* eslint-disable no-unused-expressions */

const globalValidationErrorMixin = {
	methods: {
		requiredError(field, msg) {
			const errors = [];
			const { $dirty, required } = field;
			if (!$dirty) return errors;
			!required && errors.push(msg);
			return errors;
		},
		minValueError(field, msg) {
			const errors = [];
			const { $dirty, minValue } = field;
			if (!$dirty) return errors;
			!minValue && errors.push(msg);
			return errors;
		},
		minLengthError(field, msg) {
			const errors = [];
			const { $dirty, minLength } = field;
			if (!$dirty) return errors;
			!minLength && errors.push(msg);
			return errors;
		},
		autoCompleteBoxError(field, msg) {
			const errors = [];
			const { $dirty, required, minLength } = field;
			if (!$dirty) return errors;
			!required && !minLength && errors.push(msg);
			return errors;
		},
		decimalError(field, msg) {
			const errors = [];
			const { $dirty, decimal, required } = field;
			if (!$dirty) return errors;
			(!required || !decimal) && errors.push(msg);
			return errors;
		},
	},
};

export default globalValidationErrorMixin;
