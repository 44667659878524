import { render, staticRenderFns } from "./WhyUs.vue?vue&type=template&id=968c3bba&scoped=true"
import script from "./WhyUs.vue?vue&type=script&lang=js"
export * from "./WhyUs.vue?vue&type=script&lang=js"
import style0 from "./WhyUs.vue?vue&type=style&index=0&id=968c3bba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "968c3bba",
  null
  
)

export default component.exports