<template>
	<div id="carousel" v-if="mappedCars.length > 0">
		<v-layout wrap justify-center align-center class="text-center pa-5">
			<v-flex xs12 sm12 md12 class="text-h4 font-weight-bold lg-header-link-1--text">Öne çıkan araçlar</v-flex>
			<v-flex xs12 sm12 md5 class="text-subtitle-1 py-5"> popüler araçlarımızın listesi </v-flex>
			<v-flex xs12>
				<v-sheet
					light
					max-width="1300"
					class="mx-auto"
					:class="$vuetify.breakpoint.smAndUp ? 'px-15' : ''"
					style="position: relative"
				>
					<carousel
						:responsive="responsive"
						:auto-height="true"
						:dots="false"
						:nav="false"
						:items="5"
						:margin="20"
						:stagePadding="7"
						:rewind="false"
					>
						<template slot="prev" v-show="$vuetify.breakpoint.smAndUp">
							<span class="prev">
								<v-icon right large class="template-color--text">fa fa-chevron-left</v-icon>
							</span>
						</template>
						<v-card
							v-for="(car, index) in mappedCars"
							light
							flat
							class="mx-auto rounded-lg car-card text-left"
							:to="{ name: 'carDetails', params: createURLParams(car) }"
							:key="`car-inner-${index}`"
							style="position: relative"
						>
							<div class="advantage-car advantage-car--grid pa-2" v-if="car.hasAdvantage || car.isReserved">
								<div v-if="car.hasAdvantaged">
									<v-chip small color="template-color" text-color="template-text">
										<v-icon x-small left small>fas fa-star </v-icon>
										Fırsat aracı
									</v-chip>
								</div>
								<div v-if="car.isReserved">
									<v-chip small color="template-color darken-3" text-color="template-text">
										<v-icon x-small left small>fas fa-check </v-icon>
										Opsiyonlu
									</v-chip>
								</div>
							</div>
							<v-carousel hide-delimiters height="auto">
								<div class="favorite">
									<v-btn icon width="36" height="36" @click.prevent="addToFavorites(car.id)">
										<v-icon small :color="isFavorite(car.id) ? 'red' : 'grey'">fa-heart</v-icon>
									</v-btn>
								</div>
								<template v-slot:prev="{ on, attrs }">
									<v-btn
										class="direction-icon"
										depressed
										width="35"
										height="35"
										fab
										v-bind="attrs"
										v-on="on"
										@click.prevent
									>
										<v-icon centered small>fas fa-chevron-left</v-icon>
									</v-btn>
								</template>
								<template v-slot:next="{ on, attrs }">
									<v-btn
										class="direction-icon"
										depressed
										width="35"
										height="35"
										fab
										v-bind="attrs"
										v-on="on"
										@click.prevent
										><v-icon centered small>fas fa-chevron-right</v-icon></v-btn
									>
								</template>
								<v-carousel-item v-for="(photo, pindex) in car.photos" :key="`car-photo-${pindex}`">
									<template v-slot:default>
										<v-img class="zoom-img" :class="{ 'zoom-img--hover': hasThumbImgZoom }" contain :src="`${photo}`">
											<div class="fill-height img-gradient"></div>
										</v-img>
									</template>
								</v-carousel-item>
							</v-carousel>
							<v-card-title
								class="font-weight-bold pb-0"
								:class="{ 'd-inline-block text-truncate': $vuetify.breakpoint.smAndUp }"
								:style="{ width: $vuetify.breakpoint.xsOnly ? 'auto' : '25rem' }"
								:title="title(car)"
							>
								{{ title(car) }}
							</v-card-title>
							<v-card-subtitle class="py-0 font-weight-bold car-plate">
								{{ car.plate }}
							</v-card-subtitle>
							<v-card-subtitle>
								<v-row dense no-gutters class="d-flex justify-strech">
									<template v-for="(feature, featureIndex) in car.features">
										<v-col
											class="d-flex flex-column align-center text-center feature-text"
											:class="{ 'feature-text--mobile': $vuetify.breakpoint.smAndDown }"
											:key="`feature-${featureIndex}`"
											v-if="feature.show"
										>
											<v-icon
												:small="$vuetify.breakpoint.smAndDown"
												color="template-color darken-3"
												class="feature-icon"
												>{{ feature.icon }}</v-icon
											>
											{{ feature.value }}
										</v-col>
									</template>
								</v-row>
							</v-card-subtitle>
							<v-card-text>
								<div
									class="font-weight-bold text-h5 text-md-h4 text-right d-flex"
									:class="showCredit && car.minCreditPerMonth > 0 ? 'justify-space-between' : 'justify-end'"
								>
									<div
										v-if="showCredit && car.minCreditPerMonth > 0"
										class="d-flex align-center text-left font-weight-regular"
										style="max-width: 160px; font-size: 13px; line-height: 13px"
									>
										<span class="font-weight-bold mr-1">
											{{
												car.minCreditPerMonth | tl({ fractionCount: 0, avoidEmptyDecimals: '', symbolPosition: 'back' })
											}}
										</span>
										| Aylık
									</div>
									<div>
										{{ car.salesPrice | tl({ avoidEmptyDecimals: '' }) }}
									</div>
								</div>
							</v-card-text>
						</v-card>
						<template slot="next" v-show="$vuetify.breakpoint.smAndUp">
							<span class="next">
								<v-icon left large class="template-color--text">fa fa-chevron-right</v-icon>
							</span>
						</template>
					</carousel>
				</v-sheet>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import { FETCH_POPULAR_CAR_LIST } from '@/store/modules/listing.module';
import carFavoriteMixin from '@/views/listing/pages/cars/_mixins/carFavoriteMixin';
import carFeatureMixin from '@/views/listing/pages/cars/_mixins/carFeatureMixin';
import carListMixin from '@/views/listing/pages/cars/_mixins/carListMixin';
import carousel from 'vue-owl-carousel';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'bestListingCarousel',
	mixins: [carFavoriteMixin, carFeatureMixin, carListMixin],
	components: {
		carousel,
	},
	data() {
		return {
			model: null,
			responsive: {
				0: {
					items: 1,
				},

				500: {
					items: 2,
				},
				700: {
					items: 3,
				},
				900: {
					items: 3,
				},
				1300: {
					items: 3,
				},
			},
		};
	},
	computed: {
		...mapGetters({ popularCars: 'popularCars', hasThumbImgZoom: 'hasThumbImgZoom' }),
		mappedCars() {
			return this.popularCars.map((car) => ({
				...car,
				features: this.mapFeatures(car),
			}));
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.fetchPopularCars();
		});
	},
	methods: {
		...mapActions({
			fetchPopularCars: FETCH_POPULAR_CAR_LIST,
		}),
	},
};
</script>

<style lang="scss" scoped>
::v-deep {
	.owl-carousel .owl-stage-outer .owl-item {
		a {
			box-shadow: 0 4px 8px rgb(0 70 120 / 8%), 0 10px 20px rgb(0 0 0 / 5%) !important;
		}
	}
}

.car-plate {
	font-size: 1rem !important;
	display: inline-block;
}

.direction-icon {
	background: rgba(0, 0, 0, 0.01) !important;
}

.feature-text {
	color: #333 !important;
	font-weight: 600 !important;
	font-size: 1rem !important;
	&--mobile {
		font-size: 0.9rem !important;
	}
}

.feature-icon {
	margin-bottom: 10px;
}

.favorite {
	position: absolute;
	top: 15px;
	right: 15px;
	z-index: 7;

	.v-btn {
		box-shadow: 0px 0px 2px rgb(168, 168, 168) !important;
		background-color: rgba(255, 255, 255, 0.25) !important;
	}
}

.heart {
	width: 100px;
	height: 100px;
	background: url('https://cssanimation.rocks/images/posts/steps/heart.png') no-repeat;
	background-position: 0 0;
	cursor: pointer;
	transition: background-position 1s steps(28);
	transition-duration: 0s;
	transform: scale(0.6);

	&.is-active {
		transition-duration: 1s;
		background-position: -2800px 0;
	}
}
.zoom-img--hover {
	background-position: center;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	&:hover {
		-webkit-transform: scale(1.2);
		-moz-transform: scale(1.2);
		-o-transform: scale(1.2);
		-ms-transform: scale(1.2);
		transform: scale(1.2);
		filter: blur(1.2px);
		-webkit-filter: blur(1.2px);
	}
}

.zoom-img {
	.img-gradient {
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, transparent 100px);
	}
}

::v-deep {
	.owl-carousel {
		.owl-stage-outer {
			padding: 5px 0px 30px 0px !important;
			.owl-item {
				cursor: pointer;
			}
		}
	}
	.prev {
		position: absolute;
		left: 0px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
	}
	.next {
		position: absolute;
		right: 0px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
	}

	.advantage-car {
		position: absolute;
		left: 15px;
		top: 15px;
		z-index: 5000;

		&--grid {
			left: 5px !important;
			top: 5px !important;
		}
	}
}
</style>
