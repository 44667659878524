<template>
	<v-col cols="12" md="4" class="search-bar mx-auto my-5">
		<v-text-field
			placeholder="Lütfen aramak istediğiniz kelimeyi giriniz."
			light
			flat
			class="search-bar--input"
			solo
			hide-details="auto"
			@keypress.enter="search"
			v-model="keyword"
		>
			<template v-slot:append>
				<v-btn icon>
					<v-icon small v-text="'fa-search'" color="grey lighten-3" @click="search"></v-icon>
				</v-btn>
			</template>
		</v-text-field>
	</v-col>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'Search',
	data() {
		return {
			keyword: null,
		};
	},
	computed: {
		...mapGetters(['filterPayload']),
		filterOptions() {
			return this.filterPayload;
		},
	},
	methods: {
		search() {
			if (this.keyword === null) return;
			this.filterOptions.searchText = this.keyword;
			this.$router.push({ name: 'cars' });
		},
	},
};
</script>

<style lang="scss" scoped>
.search-bar {
	&--input {
		border: 1px solid #e7e7e7;
	}
}
</style>
