<template>
	<v-layout :class="layoutJustify" v-if="getSite">
		<v-card elevation="1" class="sell-tab rounded-lg" max-width="400" v-if="hasListing && hasLeadgenB">
			<v-card-text class="rounded-lg pa-0">
				<v-tabs
					v-model="tab"
					background-color="template-color darken-1"
					color="template-text"
					class="rounded-lg rounded-b-0"
					slider-color="template-color darken-3"
					active-class="template-color darken-2"
					slider-size="5"
					height="60"
					align-with-title
					fixed-tabs
					show-arrows
				>
					<v-tab class="rounded-0 text-capitalize">
						<v-icon class="pr-2" small>fas fa-turkish-lira-sign</v-icon> Aracınızı Satın
					</v-tab>
					<v-tab class="rounded-0 text-capitalize"> <v-icon class="pr-2" small>fas fa-key</v-icon> Araç Alın </v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab" class="white rounded-lg rounded-t-0">
					<v-tab-item>
						<v-card light class="rounded-0 pa-3" elevation="0">
							<v-card-text>
								<v-row dense>
									<v-col cols="12">
										<v-select
											light
											:items="getYears"
											placeholder="Yıl *"
											v-model="carInfo.year"
											outlined
											item-text="value"
											item-value="id"
											@change="yearChanged"
											hide-details
											class="rounded-l-lg custom-select-color-tab"
										></v-select>
									</v-col>
									<v-col cols="12">
										<v-select
											light
											:items="getBrands"
											placeholder="Marka *"
											v-model="carInfo.brand"
											outlined
											item-text="value"
											item-value="id"
											return-object
											:disabled="brandIsDisabled"
											hide-details
											class="custom-select-color-tab"
										></v-select>
									</v-col>
									<v-col cols="12" class="mt-2">
										<v-btn
											:loading="loading"
											tile
											color="template-color template-text--text"
											block
											shaped
											depressed
											height="45"
											:to="{ name: 'get-offer', params: { step: 'arac-bilgileri' } }"
											class="text-capitalize font-weight-bold get-offer-btn"
											:class="{ 'rounded-lg': !$vuetify.breakpoint.mobile }"
											@click="getAnOffer"
										>
											Aracımı Değerle
										</v-btn>
									</v-col>
								</v-row>
								<v-row
									dense
									no-gutters
									class="d-flex py-1 red--text"
									v-if="getSite.isGivePrice && $v.carInfo.$dirty && $v.carInfo.$invalid"
								>
									<v-col cols="12">
										<v-icon left :size="15" color="red">fa-circle-info</v-icon>
										Lütfen yıl ve marka seçimi yapınız.
									</v-col>
								</v-row>
								<v-row dense no-gutters class="d-flex py-1 red--text" v-if="!getSite.isGivePrice">
									<v-col cols="12">
										<v-icon left :size="15" color="red">fa-circle-info</v-icon>
										Geçici olarak araç değerleme işlemi yapamıyoruz.
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-tab-item>
					<v-tab-item>
						<v-card flat light class="rounded-0 pa-3">
							<v-card-text>
								<v-row dense>
									<v-col cols="12">
										<v-select
											light
											:items="getBrands"
											placeholder="Marka *"
											v-model="listing.brand"
											outlined
											item-text="value"
											item-value="id"
											@change="brandChanged"
											hide-details
											class="rounded-l-lg custom-select-color-tab"
										></v-select>
									</v-col>
									<v-col cols="12">
										<v-select
											light
											:items="getModels"
											placeholder="Model *"
											v-model="listing.model"
											outlined
											item-text="value"
											item-value="id"
											return-object
											:disabled="sellModelIsDisabled"
											hide-details
											class="custom-select-color-tab"
										></v-select>
									</v-col>
									<v-col cols="12" class="mt-2">
										<v-btn
											:loading="loading"
											tile
											color="template-color template-text--text"
											block
											shaped
											depressed
											height="45"
											class="text-capitalize font-weight-bold get-offer-btn"
											:class="{ 'rounded-lg': !$vuetify.breakpoint.mobile }"
											@click="goToListingPlatform"
										>
											Tüm Araçları Göster
										</v-btn>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-tab-item>
				</v-tabs-items>
			</v-card-text>
		</v-card>

		<template v-if="hasListing && !hasLeadgenB">
			<v-flex md3 class="pa-0">
				<v-select
					light
					:items="getBrands"
					label="Marka"
					v-model="listing.brand"
					outlined
					item-text="value"
					item-value="id"
					@change="brandChanged"
					hide-details
					height="75"
					class="rounded-r-0 rounded-l-lg custom-select-color"
				></v-select>
			</v-flex>
			<v-flex md3 class="pa-0">
				<v-select
					light
					:items="getModels"
					label="Model"
					v-model="listing.model"
					outlined
					item-text="value"
					item-value="id"
					return-object
					:disabled="sellModelIsDisabled"
					hide-details
					height="75"
					class="rounded-0 custom-select-color"
				></v-select>
			</v-flex>
			<v-flex md4 lg3 xl2 class="pa-0">
				<v-btn
					:loading="loading"
					tile
					color="template-color template-text--text"
					block
					shaped
					depressed
					height="77"
					class="text-capitalize font-weight-bold text-body-1 get-offer-btn px-5"
					:class="{ 'rounded-l-0 rounded-r-lg': !$vuetify.breakpoint.mobile }"
					@click="goToListingPlatform"
				>
					<v-icon left size="18" color="template-text">fas fa-caret-right</v-icon>
					Tüm Araçları Göster</v-btn
				>
			</v-flex>
		</template>

		<template v-if="hasLeadgenB && !hasListing">
			<v-flex md3 class="pa-0">
				<v-select
					light
					:items="getYears"
					label="Yıl *"
					v-model="$v.carInfo.year.$model"
					outlined
					item-text="value"
					item-value="id"
					@change="yearChanged"
					hide-details
					height="75"
					class="rounded-r-0 rounded-l-lg custom-select-color"
				></v-select>
			</v-flex>
			<v-flex md3 class="pa-0">
				<v-select
					light
					:items="getBrands"
					label="Marka *"
					v-model="$v.carInfo.brand.$model"
					outlined
					item-text="value"
					item-value="id"
					return-object
					:disabled="brandIsDisabled"
					hide-details
					height="75"
					class="rounded-0 custom-select-color"
				></v-select>
			</v-flex>
			<v-flex md4 lg3 xl2 class="pa-0">
				<v-btn
					:loading="loading"
					tile
					color="template-color template-text--text"
					block
					shaped
					depressed
					height="77"
					:to="{ name: 'get-offer', params: { step: 'arac-bilgileri' } }"
					class="text-capitalize font-weight-bold text-body-1 get-offer-btn px-5"
					:class="{ 'rounded-l-0 rounded-r-lg': !$vuetify.breakpoint.mobile }"
					@click="getAnOffer"
				>
					<v-icon left size="18" color="template-text">fas fa-caret-right</v-icon>
					Hemen Teklif Al</v-btn
				>
			</v-flex>
		</template>
	</v-layout>
</template>

<script>
import { wizardStep } from '@/common/enums';
import modelValidationMixin from '@/mixins/validations/carWizard/modelValidationMixin';
import { GET_WIZARD_DATA, GET_WIZARD_YEARS, RESET_STATE, SET_CAR_WIZARD } from '@/store/modules/carWizard.module';
import { validationMixin } from 'vuelidate';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'Model',
	props: {
		justify: {
			type: String,
			require: false,
		},
	},
	mixins: [validationMixin, modelValidationMixin],
	data() {
		return {
			tab: null,
			carInfo: {
				year: null,
				brand: null,
			},
			listing: {
				brand: null,
				model: null,
			},
			loading: false,
		};
	},
	computed: {
		...mapGetters([
			'getSite',
			'hasListing',
			'hasLeadgenB',
			'getWizardCarInfo',
			'getYears',
			'getBrands',
			'getModels',
			'darkTemplate',
			'filterPayload',
		]),
		filterOptions() {
			return this.filterPayload;
		},
		brandIsDisabled() {
			return this.getBrands.length === 0;
		},
		sellModelIsDisabled() {
			return this.getModels.length === 0;
		},
		layoutJustify() {
			if (this.darkTemplate) return 'justify-';
			const listingJustify = this.$vuetify.breakpoint.mdAndDown ? 'justify-center' : 'justify-start';
			return listingJustify;
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		...mapActions({
			setCarWizard: SET_CAR_WIZARD,
			getWizardYears: GET_WIZARD_YEARS,
			getWizardData: GET_WIZARD_DATA,
			resetState: RESET_STATE,
		}),
		init() {
			this.$nextTick(() => {
				if (this.hasListing && this.hasLeadgenB) {
					this.getWizardYears();
					this.fetchBrands();
				}
				if (this.hasListing && !this.hasLeadgenB) this.fetchBrands();
				if (this.hasLeadgenB && !this.hasListing) this.getWizardYears();
			});
		},
		fetchBrands() {
			// todo : Api should be get just brands
			this.yearChanged(this.$moment().add(-1, 'year').format('YYYY'));
		},
		yearChanged(year) {
			const payload = {
				year,
			};
			this.getWizardData({ payload, fillArrayField: 'brands' });
		},
		brandChanged(brand) {
			const payload = {
				year: this.$moment().add(-1, 'year').format('YYYY'),
				brandId: brand,
			};
			this.getWizardData({ payload, fillArrayField: 'models' });
		},
		getAnOffer() {
			this.$v.carInfo.$touch();
			if (this.$v.carInfo.$invalid) return;
			if (!this.getSite.isGivePrice) return;
			this.loading = true;
			this.setCarWizard({ carInfo: { ...this.getWizardCarInfo, ...this.carInfo }, step: wizardStep.CAR_INFO })
				.then(() => {
					this.$router.push({ name: 'get-offer', params: { step: 'arac-bilgileri' } });
					this.$vuetify.goTo(0);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		goToListingPlatform() {
			this.filterOptions.brands = this.listing.brand !== null ? [this.listing.brand] : [];
			this.filterOptions.models = this.listing.model !== null ? [this.listing.model.id] : [];
			setTimeout(() => {
				this.$router.push({ name: 'cars' });
			}, 1000);
		},
	},
	watch: {
		tab: {
			handler(value) {
				this.carInfo = {
					year: null,
					brand: null,
				};
				this.listing = {
					brand: null,
					model: null,
				};
				this.resetState();

				if (value === 0) this.getWizardYears();
				else if (value === 1) this.fetchBrands();
			},
		},
	},
};
</script>
<style lang="scss" scoped>
::v-deep {
	.custom-select-color {
		// box-shadow: rgb(221, 221, 221) 0px 0px 10px 0.5px;
		border: 1px solid rgb(209, 209, 209) !important;
		border-right: none !important;
		&.v-text-field--outlined {
			fieldset {
				border: none !important;
			}
		}

		.v-select__slot {
			margin-top: 8px !important;
		}

		.v-label {
			color: #333 !important;
			font-weight: bold !important;
		}
	}

	.custom-select-color-tab {
		// box-shadow: rgb(221, 221, 221) 0px 0px 10px 0.5px;
		border: 1px solid rgb(209, 209, 209) !important;
		&.v-text-field--outlined {
			fieldset {
				border: none !important;
			}
		}

		.v-label {
			color: #333 !important;
			font-weight: bold !important;
		}
	}

	.get-offer-btn {
		background: linear-gradient(
			270deg,
			var(--v-template-color-base) 0%,
			var(--v-template-color-darken2) 100%
		) !important;
	}

	.sell-tab {
		.v-tab {
			color: var(--v-template-text-darken1) !important;
			&--active {
				color: var(--v-template-text) !important;

				.v-icon {
					color: var(--v-template-text) !important;
				}
			}

			.v-icon {
				color: var(--v-template-text) !important;
			}
		}
	}
}
</style>
