<template>
	<div wrap class="text-center">
		<div v-if="getPrice === null" class="d-flex flex-column justify-center align-center py-5" style="min-height: 300px">
			<v-img
				lazy-src="https://mars-contents.fra1.digitaloceanspaces.com/dev/vehicle-image/covered-car"
				src="https://mars-contents.fra1.digitaloceanspaces.com/dev/vehicle-image/covered-car"
				max-width="500"
				contain
			></v-img>
			<div v-if="calculateError" class="text-h6 template-color--text">
				{{ calculateErrorText }}
				<div>
					<v-btn
						:loading="calculateLoading"
						large
						shaped
						color="template-color template-text--text"
						class="rounded-lg text-capitalize mt-5"
						@click="calculate"
						v-text="'Tekrar Dene'"
					></v-btn>
				</div>
			</div>
			<div v-else>
				<div class="mb-5">
					<p class="text-h6 template-color--text text--darken-1">{{ priceText1 }}</p>
				</div>
				<v-progress-circular indeterminate color="primary" size="80" width="5" class="mb-4"></v-progress-circular>
			</div>
		</div>
		<div v-else>
			<template v-if="darkTemplate">
				<v-card class="offer-card" elevation="0">
					<v-card-text class="pa-0">
						<v-flex xs12 v-if="$vuetify.breakpoint.mobile" class="my-5">
							<v-template v-if="priceIsZero">
								<p class="text-h5 red--text text--darken-4">{{ noUpFrontPriceGiven }}</p>
							</v-template>
							<template v-else>
								<p class="text-h6 template-color--text text--darken-1 mb-2">{{ upFrontPriceTextHeader }}</p>
								<p class="text-h2 template-color--text text--darken-1 font-weight-bold mb-2">
									{{ getPrice | tl({ avoidEmptyDecimals: '' }) }}
								</p>
								<p class="text-h6 template-color--text text--darken-1">{{ turkisLiraText }}</p>
								<v-flex xs12 v-if="!priceIsZero">
									<v-btn
										:loading="loading"
										large
										shaped
										color="button-color button-text--text"
										class="rounded-lg text-capitalize"
										@click="continueProcess"
										v-text="'Aracınızı Satmak İçin Ön Görüşme Talep Edin !'"
									></v-btn>
								</v-flex>
							</template>
						</v-flex>
						<div class="pa-3 car-bl-img d-flex justify-center align-center">
							<v-img width="662" max-height="331" :lazy-src="`${getCarImg}`" :src="`${getCarImg}`" />
						</div>
						<div class="details-box template-color template-text--text darken-1 pa-3">
							<p>{{ getWizardCarInfo.year }} {{ getWizardCarInfo.brand.value }} {{ getWizardCarInfo.model.value }}</p>
							<p>
								{{ getWizardCarInfo.bodyType.value }} {{ getWizardCarInfo.fuelType.value }}
								{{ getWizardCarInfo.transmissionType.value }}
							</p>
							<p>{{ getWizardCarInfo.version.value }}</p>
							<p>{{ getWizardCarInfo.kilometers }} KM</p>
						</div>
					</v-card-text>
				</v-card>
				<div class="text-body-1 my-5">
					Bilgilerini, kondüsyonunu ve hasar durumunu belirttiğiniz araca en uygun teklifimizi aşağıda görebilirsiniz.
					Fiyat sizin için uygunsa anında satabilirsiniz!
				</div>
			</template>
			<template v-else>
				<v-flex xs12 class="text-body-1 mt-10">
					{{ priceText1 }}
				</v-flex>
				<v-flex xs12 v-if="$vuetify.breakpoint.mobile" class="my-5">
					<v-template v-if="priceIsZero">
						<p class="text-h5 red--text text--darken-4">{{ noUpFrontPriceGiven }}</p>
					</v-template>
					<v-template v-else>
						<p class="text-h6 template-color--text text--darken-1 mb-2">{{ upFrontPriceTextHeader }}</p>
						<p class="text-h2 template-color--text text--darken-1 font-weight-bold mb-2">
							{{ getPrice | tl({ avoidEmptyDecimals: '' }) }}
						</p>
						<p class="text-h6 template-color--text text--darken-1">{{ turkisLiraText }}</p>
					</v-template>
				</v-flex>
				<v-img class="img" :lazy-src="getCarImg" :src="getCarImg" contain v-if="$vuetify.breakpoint.smAndDown"></v-img>
				<v-flex md6 sm12 xs12 class="d-flex mx-auto mb-5" :class="{ 'car-img my-10': $vuetify.breakpoint.mdAndUp }">
					<v-flex
						md6
						sm12
						xs12
						class="border-primary d-flex justify-center align-center"
						v-if="$vuetify.breakpoint.mdAndUp"
					>
						<v-img width="475" height="150" class="img" :lazy-src="`${getCarImg}`" :src="`${getCarImg}`" contain />
					</v-flex>
					<v-flex
						md6
						sm12
						xs12
						class="template-color template-text--text darken-1 details pa-3"
						:class="{ 'text-right': $vuetify.breakpoint.mdAndUp }"
					>
						<p>{{ getWizardCarInfo.year }} {{ getWizardCarInfo.brand.value }} {{ getWizardCarInfo.model.value }}</p>
						<p>
							{{ getWizardCarInfo.bodyType.value }} {{ getWizardCarInfo.fuelType.value }}
							{{ getWizardCarInfo.transmissionType.value }}
						</p>
						<p>{{ getWizardCarInfo.version.value }}</p>
						<p>{{ getWizardCarInfo.kilometers }} KM</p>
					</v-flex>
				</v-flex>
			</template>
			<v-flex xs12 v-if="!$vuetify.breakpoint.mobile" class="mb-5">
				<template v-if="priceIsZero">
					<p class="text-h5 red--text text--darken-4">{{ noUpFrontPriceGiven }}</p>
				</template>
				<template v-else>
					<p class="text-h6 template-color--text text--darken-1 mb-2">{{ upFrontPriceTextHeader }}</p>
					<p class="text-h2 template-color--text text--darken-1 font-weight-bold mb-2">
						{{ getPrice | tl({ avoidEmptyDecimals: '' }) }}
					</p>
					<p class="text-h6 template-color--text text--darken-1">{{ turkisLiraText }}</p>
				</template>
			</v-flex>
			<v-flex
				xs12
				v-if="getSite.isLocation && getSite.scheduleType === locationScheduleType.PRICERESULTPAGE"
				class="my-5 details-box template-color template-text--text darken-1 pa-3 rounded-lg"
			>
				<div class="text-left font-weight-bold">Randevu oluşturmak için lokasyon seçiniz</div>
				<v-combobox
					:items="getLocations"
					v-model="locations"
					item-text="name"
					item-value="id"
					color="white"
					dark
				></v-combobox>
			</v-flex>
			<v-flex xs12 v-if="!priceIsZero">
				<v-btn
					:loading="loading"
					large
					shaped
					color="button-color button-text--text"
					class="rounded-lg text-capitalize"
					@click="continueProcess"
					v-text="'Aracınızı Satmak İçin Ön Görüşme Talep Edin !'"
				></v-btn>
			</v-flex>
			<v-flex xs12 class="my-5 text-body-1">
				Size özel olan bu teklifin geçerliliği {{ getSite.offerValidityDay }} iş günüdür. Hesaplama verdiğiniz bilgilere
				göre yapılmıştır. Nihai teklifimiz ekspertiz işleminden sonra belirlenir.
			</v-flex>
		</div>
	</div>
</template>

<script>
import { locationScheduleType } from '@/common/enums';
import { mapActions, mapGetters } from 'vuex';
import { SET_TRACKING_NUMBER, CALCULATE_PRICE } from '@/store/modules/carWizard.module';

export default {
	name: 'Offer',
	data() {
		return {
			locationScheduleType,
			calculateLoading: false,
			loading: false,
			calculateError: false,
			calculateErrorText: '',
			locations: [],
		};
	},
	computed: {
		...mapGetters([
			'getWizardCarInfo',
			'getPrice',
			'getCarImg',
			'darkTemplate',
			'getSite',
			'getLocations',
			'getIsLocation',
			'getScheduleType',
		]),
		priceIsZero() {
			return this.getPrice === 0;
		},
		priceText1() {
			return 'Bilgilerini, kondüsyonunu ve hasar durumunu belirttiğiniz araca en uygun teklifimizi aşağıda görebilirsiniz. Fiyat sizin için uygunsa anında satabilirsiniz!';
		},
		noUpFrontPriceGiven() {
			return 'Bu araç için şuanda ön fiyat teklifi veremiyoruz.';
		},
		upFrontPriceTextHeader() {
			return 'Ön Fiyat Teklifimiz';
		},
		turkisLiraText() {
			return 'Türk Lirası';
		},
	},
	created() {
		this.$nextTick(() => {
			this.calculate();
		});
	},
	methods: {
		...mapActions({
			setTrackingNumber: SET_TRACKING_NUMBER,
			calculatePrice: CALCULATE_PRICE,
		}),
		calculate() {
			if (typeof this.getPrice === 'number') return;

			this.calculateLoading = true;
			this.calculateError = false;

			this.calculatePrice()
				.catch((response) => {
					this.calculateError = true;
					if (response.responseCode === 10002) {
						this.calculateErrorText = 'Aracınız alım kriterleri dışındadır.';
					} else {
						this.calculateErrorText =
							'Ön Fiyat teklifiniz oluşturulurken bir hata oluştu. Lütfen kısa bir süre sonra tekrar deneyiniz...';
					}
				})
				.finally(() => {
					this.calculateLoading = false;
				});
		},
		continueProcess() {
			const payload = { locationId: this.locations.id };
			this.loading = true;
			this.setTrackingNumber(payload)
				.then(() => {
					this.$vuetify.goTo(0);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
<style lang="scss" scoped>
::v-deep {
	.offer-card {
		border: 1px solid #d8d8d8;

		.details-box {
			p {
				margin: 3px 0px;
			}

			.car-bl-img {
				max-height: 331px;
				overflow: hidden;
			}
		}
	}

	.car-img {
		left: 100px;
		position: relative;
		.border {
			&-primary {
				border: 1px solid var(--v-template-color-base);
			}
		}
		.img {
			position: absolute;
			left: -50%;
			margin-left: 125px;
		}
		.details {
			font-size: 16px;
			p {
				margin: 4px 0px;
			}
		}
	}
}
</style>
